import { useEffect, useState } from "react";
import { LOGIN_METHODS } from "./useAvailableLoginMethods";

const useSelectedLoginMethod = availableLoginMethods => {
  const [selectedLoginMethod, setSelectedLoginMethod] = useState(undefined);

  const reset = () => {
    setSelectedLoginMethod(undefined);
  };

  useEffect(() => {
    if (!selectedLoginMethod && availableLoginMethods.length === 1) {
      setSelectedLoginMethod(availableLoginMethods[0]);
    }
  }, [availableLoginMethods, selectedLoginMethod]);

  const isLoginFormSelected = () => selectedLoginMethod === LOGIN_METHODS.LOGIN_FORM;
  const isBankIDSelected = () => selectedLoginMethod === LOGIN_METHODS.BANKID;

  return { selectedLoginMethod, setSelectedLoginMethod, reset, isLoginFormSelected, isBankIDSelected };
};

export default useSelectedLoginMethod;
