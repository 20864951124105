export const APPLICATIONS_LIST = "APPLICATIONS_LIST";
export const AUTHORIZATION_LIST = "AUTHORIZATION_LIST";
export const COMFLOW_SESSION_ID = "COMFLOW_SESSION_ID";
export const INITIALIZED = "INITIALIZED";
export const IS_SINGLE_SIGN_ON = "IS_SINGLE_SIGN_ON";
export const LOGIN_TOKEN = "SET_MYLOC_LOGIN_TOKEN";
export const REMEMBER_ME = "REMEMBER_ME";
export const RESET_COMFLOW_SESSION_ID = "RESET_COMFLOW_SESSION_ID";
export const RESET_SESSION = "RESET_SESSION";
export const SESSION_ID = "SESSION_ID";
export const SINGLE_SIGN_OUT = "SINGLE_SIGN_OUT";
export const SESSION_INITIALIZED = "SESSION_INITIALIZED";
export const SSO_SESSION_DATA = "SSO_SESSION_DATA";
export const USER_HAS_MESSAGE_TO_ACCEPT = "USER_HAS_MESSAGE_TO_ACCEPT";
