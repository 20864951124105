import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/logos/Myloc-logga.svg";
import { useTranslate } from "../../../language/i18n";
import { RootState } from "../../../reducers/rootReducer";
import { selectHasComflowAccess, selectHasFSPAccess } from "../../../reducers/session/sessionSelectors";
import page from "../../../utils/pages";
import { useTablet } from "../../../utils/viewport";
import LinkToComflow from "../../Comflow/LinkToComflow";
import MessageButton from "../../Message/MessageButton";
import LogoutButton from "../../shared/Buttons/LogoutButton";
import { CONTAINED } from "../../shared/SpinningButton/SpinningButton";
import CartButton from "./CartButton/CartButton";
import styles from "./Header.module.scss";
import Menu from "./Menu/Menu";
import MyMylocMenu from "./MyMylocMenu/MyMylocMenu";
import OrderMenu from "./OrderMenu/OrderMenu";
import ReceiverMenu from "./ReceiverMenu/ReceiverMenu";

function Header() {
  const isDesktop = !useTablet();
  const userLoggedIn = !!useSelector((state: RootState) => state.session.sessionId);
  const order = useSelector((state: RootState) => state.appData.order);
  const hasFSPAccess = useSelector(selectHasFSPAccess);
  const hasComflowAccess = useSelector(selectHasComflowAccess);

  const translate = useTranslate();

  const showLinkInHeader = () => {
    return hasComflowAccess && (isDesktop || !hasFSPAccess);
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.containerLeft}>
          <h1 className={styles.hidden}>{translate("SITE_TITLE")}</h1>
          <Link to={page.HOME}>
            <img src={logo} alt="Myloc logo" className={styles.logo} />
          </Link>
          {showLinkInHeader() && <LinkToComflow />}
        </div>
        {userLoggedIn && (
          <div className={styles.rightMenu}>
            {hasFSPAccess ? (
              <>
                <MessageButton />
                <MyMylocMenu />
                {order?.receiver && <ReceiverMenu />}
                <OrderMenu />
                <CartButton />
              </>
            ) : (
              hasComflowAccess && <LogoutButton widthPX={150} variant={CONTAINED} />
            )}
          </div>
        )}
      </div>
      <Menu userLoggedIn={userLoggedIn} />
    </header>
  );
}

export default Header;
