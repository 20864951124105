import { useEffect, useState } from "react";
import type { ApplicationItem } from "../../reducers/session/sessionReducer";

import { useSelector } from "react-redux";
import { useTranslate } from "../../language/i18n";
import { RootState } from "../../reducers/rootReducer";
import { selectUserHasMessageToAccept } from "../../reducers/session/sessionSelectors";
import LogoutButton from "../shared/Buttons/LogoutButton";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import { OUTLINED } from "../shared/SpinningButton/SpinningButton";

const TIMEOUT_SECONDS = 5;
const MILLIS_PER_SECONDS = 1000;

const RedirectToApplicationInfo = ({ application }: { application: ApplicationItem }) => {
  const [countdown, setCountdown] = useState(TIMEOUT_SECONDS);
  const [spinner, setSpinner] = useState(true);
  const [comflowError, setComflowError] = useState(false);
  const userHasMessageToAccept = useSelector(selectUserHasMessageToAccept);
  const translate = useTranslate();

  const message = useSelector((state: RootState) => state.dialog.message);

  useEffect(() => {
    if (userHasMessageToAccept !== null) return;

    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), MILLIS_PER_SECONDS);

      return () => clearTimeout(timer);
    } else {
      setSpinner(false);
    }
  }, [countdown, userHasMessageToAccept]);

  useEffect(() => {
    if (userHasMessageToAccept !== null) return;

    if (!comflowError && message?.value) {
      setComflowError(!!message.value);
    }
  }, [comflowError, message?.value, userHasMessageToAccept]);

  useEffect(() => {
    if (spinner && comflowError) {
      setSpinner(false);
    }
  }, [comflowError, spinner]);

  if (userHasMessageToAccept !== null) return null;

  return (
    <div>
      <>
        <p>
          {translate("YOU_ARE_BEING_REDIRECTED_TO")} {application.label}.
        </p>
        {spinner && (
          <>
            <p>{translate("AUTHENTICATION_IN_PROGRESS")}</p>
            <LoadingSpinner />
          </>
        )}
        {!spinner && !comflowError && (
          <>
            <p>
              {translate("IF_THE_APPLICATION")} {application.label} {translate("WASNT_OPENED_TRY_LINK_BELOW")}
            </p>
            <a href={process.env.REACT_APP_COMFLOW_WEBAPP}>
              {translate("OPEN")} {application.label}
            </a>
          </>
        )}
        {comflowError && (
          <p>
            {translate("UNKNOWN_ERROR_OCCURRED")}. {translate("CONTACT_SUPPORT_IF_ERROR_PERSISTS")}
          </p>
        )}
      </>
      {!spinner && <LogoutButton variant={OUTLINED} />}
    </div>
  );
};

export default RedirectToApplicationInfo;
