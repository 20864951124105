import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import storage from "../../utils/store/storage";
import { SINGLE_SIGN_ON_RESPONSE, LOGIN_TOKEN as STORE_LOGIN_TOKEN } from "../../utils/store/storeVariables";
import store from "../rootReducer";

import type { ApplicationItem } from "./sessionReducer";
import {
  APPLICATIONS_LIST,
  AUTHORIZATION_LIST,
  COMFLOW_SESSION_ID,
  INITIALIZED,
  IS_SINGLE_SIGN_ON,
  LOGIN_TOKEN,
  REMEMBER_ME,
  RESET_SESSION,
  SESSION_ID,
  SINGLE_SIGN_OUT,
  SSO_SESSION_DATA,
  USER_HAS_MESSAGE_TO_ACCEPT,
} from "./sessionVariables";

export function resetComflowSessionId(): AnyAction {
  return { type: COMFLOW_SESSION_ID, undefined };
}

export function setSessionId(sessionId: string | null, rememberMe: boolean): void {
  saveItem(SESSION_ID, sessionId, rememberMe);
  store.dispatch({ type: SESSION_ID, sessionId });
}

export function setComflowSessionId(comflowSessionId: string): PayloadAction<string> {
  return {
    type: COMFLOW_SESSION_ID,
    payload: comflowSessionId,
  };
}

export function setApplicationsList(applicationsList: ApplicationItem[]): PayloadAction<ApplicationItem[]> {
  return {
    type: APPLICATIONS_LIST,
    payload: applicationsList,
  };
}

export function setAuthorizationList(authorizationList: any): void {
  store.dispatch({ type: AUTHORIZATION_LIST, authorizationList });
}

export function setRememberMe(rememberMe: boolean): AnyAction {
  saveItem(REMEMBER_ME, rememberMe, rememberMe);

  return { type: REMEMBER_ME, rememberMe };
}

export function setLoginToken(loginToken: string | null, rememberMe: boolean): void {
  saveItem(STORE_LOGIN_TOKEN, loginToken, rememberMe);
  store.dispatch({ type: LOGIN_TOKEN, loginToken });
}

export function resetSession(): void {
  storage.deleteItem(SESSION_ID);
  storage.deleteItem(SINGLE_SIGN_ON_RESPONSE);
  store.dispatch({ type: RESET_SESSION });
}

export function setInitialized(initialized = true): void {
  store.dispatch({ type: INITIALIZED, initialized });
}

export function setUserHasMessageToAccept(message: string): void {
  store.dispatch({ type: USER_HAS_MESSAGE_TO_ACCEPT, message });
}

function saveItem(variableId: string, value: any, rememberMe: boolean): void {
  storage.saveItem(variableId, value, rememberMe);
}

export function setSSOSessionData(ssoSessionData: any): AnyAction {
  return {
    type: SSO_SESSION_DATA,
    sessionData: ssoSessionData,
  };
}

export function setIsSingleSignOn(isSingleSignOn: boolean): AnyAction {
  return {
    type: IS_SINGLE_SIGN_ON,
    isSingleSignOn,
  };
}

export function setSingleSignOut(): AnyAction {
  return {
    type: SINGLE_SIGN_OUT,
    singleSignOut: true,
  };
}
